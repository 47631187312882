<template>
  <div>
    <form class="needs-validation" @submit.prevent="showConfirmModal">
      <div class="flex flex-wrap">
        <div class="w-full xl:my-3 xl:px-3 xl:w-1/2">
          <!-- Column Content -->
          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-normal mb-2"
              for="username"
            >
              Select Agent
            </label>
            <v-select
              :options="agents"
              v-model="form.agent"
              label="display_name"
            >
            </v-select>
          </div>
        </div>

        <div class="w-full xl:my-3 xl:px-3 xl:w-1/2">
          <!-- Column Content -->
          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-normal mb-2"
              for="username"
            >
              Select Service
            </label>
            <v-select
              v-model="form.service"
              label="name"
              :options="accounts"
            ></v-select>
          </div>
        </div>

        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
          <!-- Column Content -->
          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-normal mb-2"
              for="username"
            >
              Amount
            </label>
            <input
              class="
                shadow
                appearance-none
                border
                rounded
                w-full
                py-2
                px-3
                text-gray-700
                leading-tight
                focus:outline-none focus:shadow-outline
              "
              name="name"
              v-model="form.amount"
              type="text"
              :class="{
                'is-invalid': form.errors.has('amount'),
              }"
              required
              placeholder="Enter amount"
            />
          </div>
        </div>

        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
          <!-- Column Content -->
          <div class="mb-4">
            <!-- <label
              class="block text-gray-700 text-sm font-normal mb-2"
              for="username"
            >
              Branch
            </label> -->

            <!-- <input
              v-if="user.data.staff.branch"
              readonly
              class="
                shadow
                appearance-none
                border
                rounded
                w-full
                py-2
                px-3
                text-gray-700
                leading-tight
                focus:outline-none focus:shadow-outline
              "
              name="name"
              type="text"
              :value="user.data.staff.branch.name"
              required
            /> -->
          </div>
        </div>

        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
          <!-- Column Content -->
          <button
            class="
              px-4
              py-2
              rounded
              text-white
              inline-block
              shadow-lg
              bg-blue-500
              hover:bg-blue-600
              focus:bg-blue-700
            "
            @click="showConfirmModal()"
          >
            Submit
          </button>
        </div>
      </div>
    </form>

    <div v-if="form.agent">
      <confimation
        v-show="isConfirmModalVisible"
        modalHeadline="Please confirm?"
        :isLoading="isLoading"
        :confirmMessage="`Are you sure you want to make a deposit of GMD${form.amount} for ${form.agent.entity_name}`"
        @confirmEvent="confirmRequest"
        @close="closeConfirmModal"
      />
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import Swal from "sweetalert2";
import Confimation from "../../../components/Confimation.vue";

export default {
  components: { Confimation },
  props: ["user"],
  data: () => ({
    isLoading: false,
    isConfirmModalVisible: false,
    accounts: [],
    agents: [],
    userHasConfirmed: false,
    form: new Form({
      name: "",
      service: null,
      agent: null,
      amount: "",
      branch: null,
    }),
  }),

  mounted() {
    this.loadAgents();
    this.loadAccounts();
  },

  methods: {
    async loadAccounts() {
      try {
        const { data } = await axios.get(`/accounts`);

        this.accounts = data;

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },

    async loadAgents() {
      try {
        const { data } = await axios.get(`/tx/agents`);

        this.agents = data.data;
        return true;
      } catch (e) {
        console.log("Error");
        return false;
      }
    },

    showConfirmModal() {
      this.isConfirmModalVisible = true;
    },
    closeConfirmModal() {
      this.isConfirmModalVisible = false;
    },

    confirmRequest(req) {
      console.log("User has confirmed", req);
      this.userHasConfirmed = req;

      if (this.userHasConfirmed == "yes") {
        this.onSubmit();
      }
    },

    async onSubmit() {
      // Submit the form
      this.isLoading = true;

      this.form.branch = this.user.data.staff.branch;

      try {
        await this.form.post("/tx/deposit");

        this.isLoading = false;

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Successfully Deposited",
          showConfirmButton: false,
          timer: 1500,
        });

        // this.$router.push({ path: "/dashboard" });

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },
  },
};
</script>